import * as api from '../../services/index.js'
import { getParsedError } from '../../helper/axios-error'
import i18n from '../../i18n'
import { getUpdatedUserState } from '../../helper/user-state'
import HelperWebtrekk from '@dm/helper-webtrekk'
import HelperUser from '@dm/helper-user'

export function onValidTokenExists ({ state, commit }, data) {
    commit('ON_VALID_TOKEN_EXISTS', data)
    commit('ON_SET_USER_STATE', getUpdatedUserState())
}

export function onKeycloakTokenUpdateSuccess ({ state, commit }, data) {
    commit('ON_KEYCLOAK_TOKEN_UPDATE_SUCCESS', data)
    commit('ON_SET_USER_STATE', getUpdatedUserState())
}

export function onLanguageChange({ commit }, locale) {
    let dateLocale = locale.toLowerCase().split('_')
    commit('ON_SET_LANGUAGE', dateLocale[0])

    // dayjs supports e.g 'de' 'de-at' 'de-ch' 'en' 'en-gb' 'en-au' 'es' 'es-us'...
    if (dateLocale[0] === dateLocale[1]) {
        dateLocale = dateLocale[0]
    } else if (dateLocale[0] === 'en' && dateLocale[1] === 'us') {
        dateLocale = dateLocale[0]
    } else {
        dateLocale = dateLocale.join('-')
    }

    commit('ON_SET_DATE_LOCALE', dateLocale)
}

export function unternehmensdaten ({ state, commit }, data) {
    commit('ACTIVATE_UBO_FETCHING')
    return api.getUnternehmensdaten(data).then((response) => {
        commit('ACTIVATE_UBO_SUCCESS', response)
        trackActionAction(1, 'ubo_action_unternehmensdaten', state, data)
    }).catch((err) => {
        commit('ACTIVATE_UBO_FAILED', getParsedError(err, errorMessage(err)))
        trackActionAction(1, 'ubo_action_unternehmensdaten', state, data, err)
    })
}

export function updateBusinessdata ({ state, commit }, data) {
    commit('UPDATE_UBO_SENDING')
    return api.postUnternehmensdaten(data).then((response) => {
        commit('UPDATE_UBO_SUCCESS')
        trackActionAction(2, 'ubo_action_updateBusinessdata', state, data)
        trackActionGoal('ubo_action_unternehmensdaten', data)
    }).catch((err) => {
        commit('UPDATE_UBO_FAILED', getParsedError(err, errorMessage(err)))
        trackActionAction(2, 'ubo_action_updateBusinessdata', state, data, err)
    })
}

export function sendFeedback ({ state, commit }, data) {
    return api.postFeedback(data)
}

const errorMessage = error => {
    let key = null;
    if (error && error.response) {
        if (error.response.status === 500) {
            key = 'Error.500'
        } else if (error.response.status === 400 && error.response.data.message === "Dokument nicht vom Typ PDF") {
            key = 'Error.400Dokument'
        } else if (error.response.status === 400) {
            key = 'Error.400'
        } else if (error.response.status === 302) {
            key = 'Error.302'
        }
    } else {
        key = 'Error.BACKENDDEV'
    }
    if (key) {
        return i18n.t(key)
    } else {
        return null
    }
}

/**
 * @param {Number} pageCode
 * @param {String} name
 * @param {UserState} state
 * @param {Object} data
 * @param {Object} err
 */
function trackActionAction (pageCode, name, state, data, err) {
    let properties = null
    if (err) {
        properties = {
            8: getDescriptionFromPageCodeAndData(pageCode, data),
            12: getCrefoNumberFromData(data),
            10: pageCode,
            3: getParsedError(err, "", "WebtrekkAktivierung").defaultErrorMessage,
            4: getClzFromUserState(state),
            5: "ERROR",
            6: "UBO",
        }
    } else {
        properties = {
            8: getDescriptionFromPageCodeAndData(pageCode, data),
            12: getCrefoNumberFromData(data),
            10: pageCode,
            5: "OK",
            4: getClzFromUserState(state),
            6: "UBO"
        }
    }
    HelperWebtrekk.trackAction(name, properties)
}

/**
 * @param {Number} pageCode
 * @param {String} name
 * @param {UserState} state
 * @param {Object} data
 * @param {Object} err
 */
function trackActionGoal (name, data) {
    const goalParameters = {
        19: 'UBO'

    }
    const actionParameters = {
        18: 'meinecreditreform',
        19: getMethodDescriptionFromData(data)
    }
    HelperWebtrekk.trackGoal(name, goalParameters, actionParameters)
}

/**
 * @param {UserState} state
 *
 */
function getClzFromUserState (state) {
    let clz = ''
    if (state && state.unternehmensdaten && state.unternehmensdaten.kopfdaten && state.unternehmensdaten.kopfdaten.clz) {
        clz = state.unternehmensdaten.kopfdaten.clz
    }
    return clz
}

/**
 * @param {Object} data
 *
 */
function getCrefoNumberFromData (data) {
    let result = null
    if (data) {
        if (data.crefoNumber) {
            result = data.crefoNumber
        }
        if (data.crefonummer) {
            result = data.crefonummer
        }
    }
    return result
}

/**
 * @param {Number} pageCode
 * @param {Object} data
 *
 */
function getDescriptionFromPageCodeAndData (pageCode, data) {
    let result = ''
    if (data) {
        if (pageCode === 1) {
            if (data.crefoNumber && HelperUser.hasRoleUnternehmensagent(data.crefoNumber)) {
                result = 'Anmeldung als Unternehmensagent'
            } else {
                result = 'Anmeldung als Vertretungsberechtigter'
            }
        }
        if (pageCode === 2) {
            if (data.crefonummer && HelperUser.hasRoleUnternehmensagent(data.crefonummer)) {
                result = 'Absenden als Unternehmensagent'
            } else {
                result = 'Absenden als Vertretungsberechtigter'
            }
        }
    }
    return result
}

/**
 * @param {Object} data
 *
 */
function getMethodDescriptionFromData (data) {
    let result = ''
    if (data) {
        result = 'digital'
    }
    return result
}
